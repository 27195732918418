import type { WidgetInstallationType } from '@wix/platform-editor-sdk';
import { EditorReadyFn } from '@wix/yoshi-flow-editor';

const presets = { desktop: 'variants-l4fed20c', mobile: 'variants-l4fed20c' };

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefId,
  options,
  flowAPI,
) => {
  if (options.firstInstall) {
    await editorSDK.application.appStudioWidgets.addWidget('', {
      widgetId: '95e0bdd0-f841-472f-b1f0-adebc4bfd8e4',
      installationType: 'closed' as WidgetInstallationType,
      scopedPresets: {
        desktop: {
          layout: presets.desktop,
          style: presets.desktop,
        },
        mobile: {
          layout: presets.mobile,
          style: presets.mobile,
        },
      },
      layout: {
        height: 600,
        width: 960,
        x: 0,
        y: 100,
      },
    });
  }
};
